import React from "react";
import "./OrdinalShift.scss"; // Import CSS

// Function to get the ordinal suffix for a number
const getOrdinalSuffix = (number: number): string => {
  const lastDigit = number % 10;
  const lastTwoDigits = number % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) return "th";

  switch (lastDigit) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

// Define the props interface for OrdinalShift
interface OrdinalShiftProps {
  number?: any;
}

// Component that displays the number and its ordinal suffix
const OrdinalShift: React.FC<OrdinalShiftProps> = ({ number }) => {
  const suffix = getOrdinalSuffix(number);

  return (
    <div className="number-container">
      <span className="number">{number}</span>
      <sup className="ordinal">{suffix}</sup>
    </div>
  );
};
export default OrdinalShift;
