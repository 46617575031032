import React, { ReactNode, useEffect, useRef, useState } from "react";
import "./SubmitAssessmentHeader.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import i18n from "../../../i18nConfig";
import LogoTagline from "../../../utils/Images/LogoTagline";
interface ListingHeader {
  children?: ReactNode;
  isMenuHide?: any;
}
const SubmitAssessmentHeader: React.FC<ListingHeader> = ({
  children,
  isMenuHide,
}) => {
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const popoverRef: any = useRef(null);
  const iconRef: any = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const email = localStorage.getItem("emailId");
  const currentLanguage = i18n.language;
  const togglePopover = () => {
    setPopoverVisible((prevVisible) => !prevVisible);
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setPopoverVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleLogout = () => {
    localStorage.removeItem("applicantToken");
    localStorage.removeItem("applicantId");
    localStorage.removeItem("emailId");
    navigate(`/${currentLanguage}/applicantLogin`);
  };
  return (
    <div className="header container-fluid">
      <div className="logo-link">
        <div className="logo">
          <LogoTagline />
        </div>
      </div>
      <div className="Btn">
        {children}
        {!isMenuHide && (
          <div className="profile-settings">
            <div ref={iconRef} onClick={togglePopover}>
              <div className="logo-place">
                {email?.charAt(0).toLocaleUpperCase()}
              </div>
            </div>
            {isPopoverVisible && (
              <div className="popover" ref={popoverRef}>
                <div className="popover-header">
                  <div className="logo-name-sec">
                    <div className="profile-icon company-logo">
                      {email?.charAt(0).toLocaleUpperCase()}
                    </div>
                    <div className="company-name">
                      <strong>{"Name"}</strong>
                      <div className="email-date-section">
                        <span>{email ?? email}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="popover-body">
                  <div className="popover-item" onClick={() => navigate("/")}>
                    {t("SUBMIT_ASSESSMENT_MY_ASSESSMENT")}
                  </div>
                  <div className="popover-item">
                    {t("GENERAL.REQUIREMENTS")}
                  </div>
                </div>
                <div className="popover-footer">
                  <hr />
                  <div className="popover-item" onClick={handleLogout}>
                    {t("GENERAL.LOGOUT")}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SubmitAssessmentHeader;
