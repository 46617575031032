/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum VideoResolution {
  WidthMin = 640,
  WidthIdeal = 640,
  WidthMax = 1280,
  HeightMin = 480,
  HeightIdeal = 480,
  HeightMax = 720,
}

export enum VideoFrameRate {
  FrameRateMin = 15,
  FrameRateIdeal = 15,
  FrameRateMax = 60,
}

export enum AudioSettings {
  SampleRateMin = 16000, // Minimum sample rate in Hz
  SampleRateIdeal = 96000, // Ideal sample rate in Hz (High-quality)
  SampleSizeMin = 8, // Minimum sample size in bits
  SampleSizeIdeal = 24, // Ideal sample size in bits (High-quality)
  ChannelCountMin = 1, // Mono sound
  ChannelCountIdeal = 2, // Stereo sound (High-quality)
}

// Update these properties as separate constants of type `boolean`.
export const AudioBooleanSettings = {
  EchoCancellation: true, // Enable echo cancellation
  NoiseSuppression: true, // Enable noise suppression
  AutoGainControl: true, // Enable auto gain control
};
