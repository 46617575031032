import "./Loader.scss";

const Loader = (props: any) => {
  const { isText } = props;
  return (
    <div className="mainLoader">
      {isText && <span>Uploading Response...</span>}
      <span className="loader"></span>
    </div>
  );
};

export default Loader;
