import { handleError } from "../../utils/Common/Constant";
import apiClient from "../Connection/Connection";

// to get getApplicantAssessmentList types
export const getApplicantAssessmentList = async (
  applicantId: string,
  applicantAssessmentStatus: string,
  currentPage: number,
  pageSize: number,
  search?: string,
) => {
  try {
    return (
      await apiClient.get(
        `assessmentApplicant/getApplicantAssessmentList?applicantId=${applicantId}&search=${search}&applicantAssessmentStatus=${applicantAssessmentStatus}&currentPage=${currentPage}&pageSize=${pageSize}`,
      )
    )?.data?.data;
  } catch (error) {
    handleError(error);
  }
};

// post api of startApplicantAssessment
export const startApplicantAssessment = async (
  applicantId: string,
  assessmentId: string,
  applicantImage: string,
  assessmentApplicantSkillExperience: any,
) => {
  const data = {
    applicantId,
    assessmentId,
    applicantImage,
    assessmentApplicantSkillExperience,
  };
  try {
    const res = await apiClient.post(
      "assessmentApplicant/startApplicantAssessment",
      data,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

// to get getApplicantAssessmentQuestion
export const getApplicantAssessmentQuestion = async (
  applicantId: string,
  assessmentId: string,
) => {
  try {
    return (
      await apiClient.get(
        `assessmentApplicant/getApplicantAssessmentQuestion?applicantId=${applicantId}&assessmentId=${assessmentId}`,
      )
    )?.data;
  } catch (error) {
    handleError(error);
  }
};

// post api of submitAndSkipApplicantAssessmentAnswer
export const submitAndSkipApplicantAssessmentAnswer = async (data: any) => {
  try {
    const res = await apiClient.post(
      "assessmentApplicant/submitAndSkipApplicantAssessmentAnswer",
      data,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

// post api of applicantAssessmentFeedback
export const applicantAssessmentFeedback = async (data: any) => {
  try {
    const res = await apiClient.post(
      "assessmentApplicant/applicantAssessmentFeedback",
      data,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

// post api of updateApplicantProfile
export const updateApplicantProfile = async (data: any) => {
  try {
    const res = await apiClient.put(
      "assessmentApplicant/updateApplicantProfile",
      data,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

// post api of compileApplicantQuestion
export const compileApplicantQuestion = async (data: any) => {
  try {
    const res = await apiClient.post(
      "assessmentApplicant/compileApplicantQuestion",
      data,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

// to get GetAssessment Details By AssessementId
export const getAssessmentDetailsByAssessementId = async (
  assessmentId: string,
) => {
  try {
    return (
      await apiClient.get(
        `company/companyGetAssessmentDetailsByAssessementId?assessmentId=${assessmentId}`,
      )
    )?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get GetAssessment Details By AssessementId && ApplicantId
export const getApplicantAssessmentStatusAndDetailsWithExperience = async (
  applicantId: string,
  assessmentId: string,
) => {
  try {
    return (
      await apiClient.get(
        `/assessmentApplicant/getApplicantAssessmentStatusWithExperience?applicantId=${applicantId}&assessmentId=${assessmentId}`,
      )
    )?.data;
  } catch (error) {
    handleError(error);
  }
};

//  to login applicant
export const loginApplicant = async (data: any) => {
  try {
    const res = await apiClient.post(
      "assessmentApplicant/applicantLogin",
      data,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

// register user by invite flow
export const registerApplicant = async (data: any) => {
  try {
    const res = await apiClient.post(
      "assessmentApplicant/registerApplicant",
      data,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

//  to login applicant
export const getOtp = async (data: any) => {
  try {
    const res = await apiClient.post("assessmentApplicant/getOtp", data);
    return res;
  } catch (error) {
    handleError(error);
  }
};

// to get applicant details
export const getApplicantDetails = async (applicantId: string) => {
  try {
    return (
      await apiClient.get(
        `/assessmentApplicant/applicantDetails?applicantId=${applicantId}`,
      )
    )?.data;
  } catch (error) {
    handleError(error);
  }
};
// post api of compileApplicantQuestion
export const deferredAssessment = async (data: any) => {
  try {
    const res = await apiClient.post(
      "assessmentApplicant/deferredAssessment",
      data,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

// to get applicant details
// post api of compileApplicantQuestion
export const updateAssessmentApplicantQuestionStatus = async (data: any) => {
  try {
    const res = await apiClient.put(
      "assessmentApplicant/updateAssessmentApplicantQuestionStatus",
      data,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

// put api of updateApplicantAssessmentSkillExperince
export const updateApplicantAssessmentSkillExperince = async (data: any) => {
  try {
    const res = await apiClient.put(
      "assessmentApplicant/updateApplicantAssessmentSkillExperince",
      data,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

//  add company assessmentQuestionReport
export const assessmentQuestionReport = async (data: any) => {
  try {
    const res = await apiClient.post(
      "assessmentApplicant/applicantReportQuestion",
      data,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};
//resend otp function
export const resendOtp = async (data: any) => {
  try {
    const res = await apiClient.post("company/companyUserResendOtp", data);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};
