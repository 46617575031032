import React, { useEffect, useState } from "react";
import "./User.scss";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import {
  AssessmentButton,
  InputField,
  Tooltip,
  showToast,
} from "ui-components";
import * as Yup from "yup";
import {
  getOtp,
  registerApplicant,
  resendOtp,
} from "../../../service/SubmitAssessment/SubmitAssessment";
import { useNavigate, useParams } from "react-router";
import Loader from "../../../components/Loader/Loader";
import { useSearchParams } from "react-router-dom";
import { formatTime, toPascalCase } from "../../../utils/Common/Constant";

interface User {
  setShow: any;
  setIsUserSubmit: any;
  userEmail: string | undefined;
}

const User: React.FC<User> = ({ setShow, setIsUserSubmit, userEmail }) => {
  const { assessmentIdParam } = useParams();
  const { t, i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const verificationCompleted = searchParams.get("vc");
  const [getOtpLoader, setgetOtpLoader] = useState(false);
  const [thankYouMsgToggle, setThankYouMsgToggle] = useState(
    verificationCompleted ? true : false,
  );
  const [firstTimeOTPButton, setFirstTimeOTPButton] = useState(false);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [resendBtnDisable, setResendBtnDisable] = useState(false);
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    const savedTime = sessionStorage.getItem("leftTime");
    if (savedTime !== null) {
      const timeRemaining = Number(savedTime);
      if (timeRemaining > 0) {
        setTimer(timeRemaining);
        setIsTimerActive(true);
      } else {
        setTimer(0);
        setIsTimerActive(false);
      }
    } else {
      sessionStorage.setItem("leftTime", "60");
    }

    const interval = setInterval(() => {
      setTimer((prevTime) => {
        if (prevTime > 1) {
          sessionStorage.setItem("leftTime", (prevTime - 1).toString());
          return prevTime - 1;
        } else {
          clearInterval(interval);
          setIsTimerActive(false);
          sessionStorage.setItem("leftTime", "0");
          setResendBtnDisable(false);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isTimerActive]);

  // register User Data
  const registerUserData = {
    email: userEmail ?? "",
    firstName: "",
    lastName: "",
    passCode: "",
  };
  const registerUserSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, t("ERROR.FIRST_NAME_THREE_CHAR"))
      .trim()
      .required(t("ERROR.FIRSTNAME_REQUIRED")),
    lastName: Yup.string(),
    passCode: Yup.string()
      .matches(/^[0-9]{6}$/, t("ERROR.INVALID_PASSCODE"))
      .required(t("ERROR.PASSCODE_REQUIRED")),
    email: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("ERROR.INVALID_EMAIL_ADDRESS"))
      .required(t("ERROR.EMAIL_REQUIRED")),
  });

  // email invite email data
  const inviteEmailDataWithPass = {
    email: "",
    passCode: "",
  };
  const inviteEmailSchemaWithPass = Yup.object().shape({
    passCode: Yup.string()
      .matches(/^[0-9]{6}$/, t("ERROR.INVALID_OTP"))
      .required(t("ERROR.OTP_REQUIRED")),
    email: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("ERROR.INVALID_EMAIL_ADDRESS"))
      .required(t("ERROR.EMAIL_REQUIRED")),
  });
  const inviteEmailSchema = Yup.object().shape({
    email: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("ERROR.INVALID_EMAIL_ADDRESS"))
      .required(t("ERROR.EMAIL_REQUIRED")),
  });

  // login applicant
  const handleRegisterUser = async (
    value: any,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any,
  ) => {
    const data = {
      emailId: value?.email.trim().toLowerCase(),
      otp: Number(value?.passCode.trim()),
      assessmentId: assessmentIdParam,
      firstName: toPascalCase(value?.firstName),
      lastName: toPascalCase(value?.lastName),
      isPasscode: userEmail ? true : false,
    };
    try {
      // Set isSubmitting to true to indicate that form submission is in progress
      setSubmitting && setSubmitting(true);
      const registerApplicantRes = await registerApplicant(data);
      if (registerApplicantRes?.status === 200) {
        resetForm && resetForm();
        if (registerApplicantRes?.data?.invitationSend == true) {
          setThankYouMsgToggle(true);
          navigate(
            `/${currentLanguage}/assessmentdetails/${assessmentIdParam}?vc=true`,
          );
        } else {
          setIsUserSubmit(false);
          setShow(true);
          navigate(
            `/${currentLanguage}/assessmentdetails/${assessmentIdParam}?appId=${registerApplicantRes?.data?.applicantId}&loggedIn=true`,
          );
        }
      } else {
        showToast(registerApplicantRes?.data?.customMessage, "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      // Ensure isSubmitting is set to false, even if an error occurs during submission
      setSubmitting && setSubmitting(false);
    }
  };

  // get otp
  const getOTP = async (
    emailId: string,
    setSubmitting?: (data: boolean) => any,
  ) => {
    const data = {
      emailId: emailId,
      assessmentId: assessmentIdParam,
    };
    try {
      setSubmitting && setSubmitting(true);
      setgetOtpLoader(true);
      const sendInvitationRes = await getOtp(data);
      if (sendInvitationRes?.data?.status === 200) {
        setFirstTimeOTPButton(true);
        showToast(sendInvitationRes?.data?.data?.message, "success");
        if (sendInvitationRes?.data?.data?.needToLogin == true) {
          navigate(`/${currentLanguage}/applicantLogin`);
        }
      } else {
        showToast(sendInvitationRes?.data?.customMessage, "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setgetOtpLoader(false);
      setSubmitting && setSubmitting(false);
    }
  };
  // resend otp function
  const resendOtpApi = async (email: string) => {
    setTimer(60);
    sessionStorage.setItem("leftTime", "60");
    setResendBtnDisable(true);
    try {
      const res = await resendOtp({ email: email, type: "Applicant" });
      sessionStorage.setItem("leftTime", "60");
      showToast(res.customMessage, "success");
      setIsTimerActive(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      {thankYouMsgToggle ? (
        <>
          <div className="userSec">
            <div className="userContent">
              <h3 className="userHeading thankyouHead">
                {t("GENERAL.THANKYOU_FOR_VERIFICATION")}
              </h3>
              <span className="thankyouContent">
                {t("GENERAL.SENT_LINK_TO_EMAIL")}
              </span>
              <AssessmentButton
                className="submitBtn"
                onClick={() => {
                  navigate(`/${currentLanguage}/applicantLogin`);
                }}
              >
                {t("GENERAL.GO_BACK")}
              </AssessmentButton>
            </div>
          </div>
        </>
      ) : (
        <>
          {!userEmail ? (
            <Formik
              initialValues={inviteEmailDataWithPass}
              validationSchema={
                firstTimeOTPButton
                  ? inviteEmailSchemaWithPass
                  : inviteEmailSchema
              }
              onSubmit={(value, { resetForm, setSubmitting }) => {
                if (!firstTimeOTPButton) {
                  getOTP(value.email, setSubmitting);
                } else {
                  handleRegisterUser(value, resetForm, setSubmitting);
                }
              }}
            >
              {({ errors, touched, values, setFieldValue, isSubmitting }) => {
                return (
                  <Form>
                    <div className="userSec">
                      <div className="userContent">
                        <h1 className="userHeading">
                          {t("GENERAL.EMAIL_INVITATION")}
                        </h1>

                        <Field name="email">
                          {() => (
                            <InputField
                              placeholder={`${t("GENERAL.EMAIL")} *`}
                              onChange={(newValue: any) =>
                                setFieldValue("email", newValue.trim())
                              }
                              value={values.email}
                              additionalProps={{
                                className: `emailInput mt-5 ${firstTimeOTPButton ? "" : "customMargin"}`,
                                disabled: userEmail ? true : false,
                              }}
                            />
                          )}
                        </Field>
                        {errors?.email && touched?.email ? (
                          <div className="errorMessage">{errors?.email}</div>
                        ) : null}

                        {firstTimeOTPButton ? (
                          <>
                            <Field name="passCode">
                              {() => (
                                <div className="passwordSec">
                                  <InputField
                                    placeholder={`${t("GENERAL.OTP")} *`}
                                    onChange={(newValue) =>
                                      setFieldValue("passCode", newValue)
                                    }
                                    type="password"
                                    value={values.passCode}
                                    additionalProps={{
                                      className: "otpInput",
                                    }}
                                  />
                                  {!userEmail ? (
                                    <AssessmentButton
                                      type="button"
                                      onClick={() => resendOtpApi(values.email)}
                                      disabled={resendBtnDisable}
                                    >
                                      {getOtpLoader ? (
                                        <Loader isText={false} />
                                      ) : (
                                        t("GENERAL.RESEND_OTP")
                                      )}
                                    </AssessmentButton>
                                  ) : (
                                    <Tooltip
                                      classname="tooltipOverCss"
                                      text={t("GENERAL.ALREADY_SEND_OTP")}
                                    />
                                  )}
                                </div>
                              )}
                            </Field>
                            <div className="otpError">
                              {errors?.passCode && touched?.passCode ? (
                                <div className="errorMessage">
                                  {errors?.passCode}
                                </div>
                              ) : (
                                <div></div>
                              )}
                              <div className="resendOtpSec">
                                {isTimerActive && (
                                  <span>
                                    &nbsp;
                                    {formatTime(Number(timer))}
                                  </span>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <AssessmentButton
                            className="getOtpBtn"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {isSubmitting
                              ? t("GENERAL.LOADING")
                              : t("GENERAL.GET_OTP")}
                          </AssessmentButton>
                        )}

                        {firstTimeOTPButton && (
                          <AssessmentButton
                            className="submitBtn mt-0"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {isSubmitting
                              ? t("GENERAL.LOADING")
                              : t("GENERAL.SUBMIT")}
                          </AssessmentButton>
                        )}
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <Formik
              initialValues={registerUserData}
              validationSchema={registerUserSchema}
              onSubmit={(value, { resetForm, setSubmitting }) => {
                handleRegisterUser(value, resetForm, setSubmitting);
                // sendInvitation(value?.inviteEmail, resetForm, setSubmitting);
              }}
            >
              {({ errors, touched, values, setFieldValue, isSubmitting }) => {
                return (
                  <Form>
                    <div className="userSec">
                      <div className="userContent">
                        <h1 className="userHeading">
                          {t("GENERAL.VERIFY_YOURSELF")}
                        </h1>
                        <Field name="firstName">
                          {() => (
                            <InputField
                              placeholder={`${t("GENERAL.FIRST_NAME")} *`}
                              onChange={(newValue) =>
                                setFieldValue("firstName", newValue)
                              }
                              value={values.firstName}
                              additionalProps={{ className: "firstNameInput" }}
                            />
                          )}
                        </Field>
                        {errors?.firstName && touched?.firstName ? (
                          <div className="errorMessage">
                            {errors?.firstName}
                          </div>
                        ) : null}

                        <Field name="lastName">
                          {() => (
                            <InputField
                              placeholder={t("GENERAL.LAST_NAME")}
                              onChange={(newValue) =>
                                setFieldValue("lastName", newValue)
                              }
                              value={values.lastName}
                              additionalProps={{ className: "lastNameInput" }}
                            />
                          )}
                        </Field>
                        {errors?.lastName && touched?.lastName ? (
                          <div className="errorMessage">{errors?.lastName}</div>
                        ) : null}

                        <Field name="email">
                          {() => (
                            <InputField
                              placeholder={`${t("GENERAL.EMAIL")} *`}
                              onChange={(newValue) =>
                                setFieldValue("email", newValue)
                              }
                              value={values.email}
                              additionalProps={{
                                className: "emailInput ",
                                disabled: userEmail ? true : false,
                              }}
                            />
                          )}
                        </Field>
                        {errors?.email && touched?.email ? (
                          <div className="errorMessage">{errors?.email}</div>
                        ) : null}

                        <Field name="passCode">
                          {() => (
                            <div className="passwordSec">
                              <InputField
                                placeholder={`${t("GENERAL.PASS_CODE")} *`}
                                onChange={(newValue) =>
                                  setFieldValue("passCode", newValue)
                                }
                                type="password"
                                value={values.passCode}
                                additionalProps={{ className: "passwordInput" }}
                              />
                              {!userEmail ? (
                                <AssessmentButton
                                  type="button"
                                  onClick={() => getOTP(values.email)}
                                  disabled={getOtpLoader}
                                >
                                  {getOtpLoader ? (
                                    <Loader isText={false} />
                                  ) : (
                                    t("GENERAL.GET_OTP")
                                  )}
                                </AssessmentButton>
                              ) : (
                                <Tooltip
                                  classname="tooltipOverCss"
                                  text={t("GENERAL.ALREADY_SEND_OTP")}
                                />
                              )}
                            </div>
                          )}
                        </Field>
                        {errors?.passCode && touched?.passCode ? (
                          <div className="errorMessage">{errors?.passCode}</div>
                        ) : null}

                        <AssessmentButton
                          className="submitBtn"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting
                            ? t("GENERAL.LOADING")
                            : t("GENERAL.SUBMIT")}
                        </AssessmentButton>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </>
      )}
    </div>
  );
};
export default User;
