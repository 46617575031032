import "./FullPageLoader.scss";
import Loader from "./Loader";
const FullPageLoader = (props: any) => {
  const { isText } = props;
  return (
    <div className="fullPageLoader">
      <Loader isText={isText} />
    </div>
  );
};
export default FullPageLoader;
