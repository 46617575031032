import { useTranslation } from "react-i18next";
import "./Footer.scss";
import LogoTagline from "../../utils/Images/LogoTagline";
import packageJson from "../../../package.json";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="logo">
        <LogoTagline />
      </div>
      <div className="middle">
        <p>
          © 2024, Evluate Global,&nbsp;LLC&nbsp;| &nbsp;{t("GENERAL.VERSION")}:
          {packageJson.version}
        </p>
      </div>

      <div className="base">
        <button className="getSupport"> {t("GET_SUPPORT")}</button>
        <button className="reportBug"> {t("REPORT_BUG")}</button>
      </div>
    </div>
  );
};

export default Footer;
