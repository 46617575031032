import React, { useEffect } from "react";
import Loader from "../../../components/Loader/Loader";
import "./InvitedUserAuth.scss";
// import { getSessionStorageItem } from "../../../utils/SessionStorageMethod/getSessionStorageItem";
import { validateAssessmentApplicant } from "../../../service/Invite/Invite";
import { useLocation } from "react-router-dom";
import { showToast } from "ui-components";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { extractPathFromUrl } from "../../../utils/Common/Constant";
// import { removeSessionStorageItem } from "../../../utils/SessionStorageMethod/removeSessionStorageItem";

const InvitedUserAuth: React.FC = () => {
  const { i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const applicantIdInParams = searchParams.get("q");
  const assessmentSlug = extractPathFromUrl(pathname);
  useEffect(() => {
    handleValidateAssessmentApplicant();
  }, []);

  // get ApplicantList list for search
  async function handleValidateAssessmentApplicant() {
    const applicantToken = localStorage.getItem("applicantToken");
    const validatedRes = await validateAssessmentApplicant(
      assessmentSlug ?? "",
      applicantIdInParams ?? "",
      applicantToken ?? "",
    );
    /* eslint-disable no-dupe-else-if */
    if (validatedRes?.status === 200) {
      if (
        validatedRes?.data?.systemUser == true &&
        validatedRes?.data?.needToLogin == false &&
        applicantToken
      ) {
        navigate(
          `/${currentLanguage}/assessmentdetails/${validatedRes?.data?.assessmentId}?appId=${validatedRes?.data?.applicantId}&loggedIn=true`,
        );
      } /* eslint-disable-next-line no-dupe-else-if */ else if (
        (validatedRes?.data?.systemUser == true &&
          validatedRes?.data?.needToLogin == true) ||
        (validatedRes?.data?.systemUser == true &&
          validatedRes?.data?.needToLogin == true &&
          validatedRes?.data?.message === "Token Expire")
      ) {
        showToast(validatedRes?.data?.message, "error");
        navigate(`/${currentLanguage}/applicantLogin`);
      } else if (
        validatedRes?.data?.systemUser == true &&
        validatedRes?.data?.needToLogin == true &&
        applicantToken
      ) {
        // already a user move to login
        // showToast(validatedRes?.data?.message, "error");
        navigate(`/${currentLanguage}/unauthorized-error`);
      } else if (
        validatedRes?.data?.systemUser == false &&
        validatedRes?.data?.needToRegister == true &&
        validatedRes?.data?.emailId &&
        applicantToken
      ) {
        // asc, api should call
        navigate(
          `/${currentLanguage}/assessmentdetails/${validatedRes?.data?.assessmentId}?appId=${applicantIdInParams ?? ""}&asc=false`,
          {
            state: {
              userEmail: validatedRes?.data?.emailId,
            },
          },
        );
        // removeSessionStorageItem("applicantToken");
        localStorage.removeItem("applicantToken");
      } else if (
        validatedRes?.data?.systemUser == false &&
        validatedRes?.data?.needToRegister == true &&
        validatedRes?.data?.emailId
      ) {
        // asc, api should call
        navigate(
          `/${currentLanguage}/assessmentdetails/${validatedRes?.data?.assessmentId}?appId=${applicantIdInParams ?? ""}&asc=false`,
          {
            state: {
              userEmail: validatedRes?.data?.emailId,
            },
          },
        );
      } else if (
        validatedRes?.data?.assessmentId &&
        validatedRes?.data?.needToRegister == true
      ) {
        // with otp register
        navigate(
          `/${currentLanguage}/assessmentdetails/${validatedRes?.data?.assessmentId}`,
        );
      }
    } else {
      showToast(validatedRes?.customMessage, "error");
      navigate(`/${currentLanguage}/404-not-found-error`);
    }
  }
  return (
    <div className="loadingPage">
      <div>
        <Loader isText={false} />
      </div>
    </div>
  );
};
export default InvitedUserAuth;
