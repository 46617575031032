interface Timer {
  color: string;
}
const Timer: React.FC<Timer> = ({ color }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.375 1H11.625M9.5 7.66667V12.1111L11.625 14.3333M18 12.1111C18 17.0203 14.1944 21 9.5 21C4.80558 21 1 17.0203 1 12.1111C1 7.20191 4.80558 3.22222 9.5 3.22222C14.1944 3.22222 18 7.20191 18 12.1111Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Timer;
