import moment from "moment";
import { getSessionStorageItem } from "../SessionStorageMethod/getSessionStorageItem";
import { showToast } from "ui-components";
import { v4 as uuidv4 } from "uuid";

// ar: Arabic he: Hebrew fa: Persian (Farsi) ur: Urdu
const rtlLanguages = ["ar", "he", "fa", "ur"]; // Add other RTL languages if needed

export const isRtl = (language: string) => rtlLanguages.includes(language);

// check component disability
export const isDisable = (functionBehavior: any) => {
  return functionBehavior === "disable" ? true : false;
};

// check component hidden or not
export const isHide = (functionBehavior: any) => {
  return functionBehavior === "hide" ? false : true;
};

export const initialReviewData = {
  type: getSessionStorageItem("Type") || "",
  titleHeadContent: getSessionStorageItem("titleHeadContent") || "",
};

// Utility function to format the timer value into minutes and seconds
export const formatTime = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export const updateProgressBarAndColors = (
  questionSet: any[],
  currentQuestionIndex: number,
  questionData: any,
  assessmentDetailsData: any,
  setProgressBar: (callback: any) => void,
  setSubQuestionColors: (colors: string[]) => void,
  setApiError: (error: boolean) => void,
) => {
  // to calculate progress bar width
  const progressBar = (currentQuestionIndex: number, totalQuestion: number) => {
    const equalWidth = 100 / (totalQuestion - 1);
    const widthPerCal = equalWidth * currentQuestionIndex;
    return widthPerCal - equalWidth;
  };
  if (questionSet?.length > currentQuestionIndex) {
    if (questionData?.data?.currentQuestion !== 1) {
      setProgressBar((prev: any) => {
        const updateData =
          prev.progressbar < 100
            ? {
                progressbar: progressBar(
                  questionData?.data?.currentQuestion,
                  assessmentDetailsData?.totalQuestions,
                ),
                index: prev.index + 1,
              }
            : prev;
        return updateData;
      });
    }
    if (assessmentDetailsData?.applicationStatus === "Initiated") {
      const initialUpdateData = Array.from(
        { length: questionSet.length },
        (_, index) =>
          index < questionData?.data?.currentQuestion
            ? "var(--primary-green)"
            : "var(--division-grey)",
      );
      const updatedColors = [...initialUpdateData];
      setSubQuestionColors(updatedColors);
    } else {
      const initialUpdateData = Array.from(
        { length: questionSet.length },
        (_, index) =>
          index === 0 ? "var(--primary-green)" : "var(--division-grey)",
      );
      if (questionData?.data?.currentQuestion > 1) {
        const updatedColors = Array.from(
          { length: questionSet.length },
          (_, index) =>
            index < questionData?.data?.currentQuestion
              ? "var(--primary-green)"
              : initialUpdateData[index],
        );
        setSubQuestionColors(updatedColors);
      } else {
        setSubQuestionColors(initialUpdateData);
      }
    }
    setApiError(false);
  }
};

export function extractLanguage(url: string): string | null {
  const parts = url.split("/");
  if (parts.length < 3) {
    return null; // URL doesn't have enough parts
  }
  return parts[1]; // Return the second part of the URL
}
// start edited assessment data

// transform edited custom question

// end edited assessment data
// extract path from url
export function extractPathFromUrl(url: string): string | null {
  // eslint-disable-next-line no-useless-escape
  const match = url.match(/\/a\/([^\/?#]+)/);
  return match ? match[0].substring(1) : null;
}

// handle error while calling api
export function handleError(error: any) {
  if (error?.customMessage) {
    showToast(error?.customMessage, "error");
  } else {
    console.log("error", error);
  }
}

// if we have uniqueId then use that otherwise use new
export const foundUniqueId = (id: string | undefined | null) => {
  if (id) {
    return id;
  } else {
    const uniqueId = uuidv4();
    localStorage.setItem("tempUniqueId", uniqueId);
    return uniqueId;
  }
};

export const convertDate = (
  dateTime: string,
  withComma?: boolean,
  withTime?: boolean,
) => {
  const days: { [key: string]: string } = {
    Mon: "Mon",
    Tue: "Tue",
    Wed: "Wed",
    Thu: "Thu",
    Fri: "Fri",
    Sat: "Sat",
    Sun: "Sun",
  };
  const dateFormat = moment(dateTime).format("llll").split(",");
  const year = dateFormat[2].split(" ")[1];
  const time = dateFormat[2].split(" ")[2] + " " + dateFormat[2].split(" ")[3];
  const date = dateFormat[1].split(" ")[2];
  const month = dateFormat[1].split(" ")[1];
  const day = dateFormat[0];
  if (withComma) {
    return `${date} ${month} ${year}, ${days[day]}`;
  } else if (withTime) {
    return `${date} ${month} ${year}, ${days[day]} ${time}`;
  } else {
    return `${date} ${month} ${year} (${days[day]})`;
  }
};

export const handleKeyPressSearch = (
  e: React.KeyboardEvent<HTMLInputElement>,
) => {
  if (e.key === "Enter") {
    e.preventDefault(); // Prevent default form submission on Enter key
  }
};

interface ISkillData {
  skillName: string;
  skillIcon: string;
  difficultyLevel: string;
  totalQuestions: number;
}

// manage the duplicate skill and mention the difficultyLevel
export function manageDuplicateSkills(data: ISkillData[]): any {
  if (data) {
    return (
      data.map((skill) => ({
        ...skill,
        skillName: `${skill.skillName} - ${skill.difficultyLevel}`,
      })) ?? []
    );
  } else {
    return null;
  }
}

// user login User Data
export const loginUserData = {
  email: "",
  password: "",
};

// decode text
export function convertToText(value: string) {
  if (value === undefined || value === null) {
    return "";
  } else {
    return atob(value);
  }
}

// api for to get ip and geolocation address
export const geoLocationapiClient = async () => {
  const res = await fetch("https://ipapi.co/json/");
  const ipData = res?.json();
  return ipData;
};

// get pascal string
export function toPascalCase(str: string) {
  return str
    ?.toLowerCase() // Convert the entire string to lowercase
    .split(" ") // Split the string by spaces
    .filter((word) => word) // Remove any empty strings from the array
    .map((word) => {
      // Capitalize the first letter of each word and concatenate with the rest of the word
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" "); // Join all words together without spaces
}

// get camel case string
export function toCamelCase(str: string) {
  return str
    .split(" ")
    .map((word, index) => {
      const lowerCasedWord = word.toLowerCase();
      return index === 0
        ? lowerCasedWord
        : lowerCasedWord.charAt(0).toUpperCase() + lowerCasedWord.slice(1);
    })
    .join("");
}

// this function is used for only to attempt number of question
export const attempFun = (ele: number) => {
  switch (ele) {
    case 1:
      return "1st";
    case 2:
      return "2nd";
    case 3:
      return "3rd";
    case 4:
      return "4th";
    case 5:
      return "last";
  }
};

export const exitFullScreen = () => {
  if (document.fullscreenElement) {
    document.exitFullscreen().catch((e) => {
      console.error(e);
    });
  }
};
